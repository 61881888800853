import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import prevButton from "../assets/Icons/slider-left-arrow.svg";
import nextButton from "../assets/Icons/slider-right-arrow.svg";
import "./CSS/imageSlider.css";

const ImageSlider = ({ images }) => {
  return (
    <div className="image-slider-wrapper">
      <Swiper
        modules={[Navigation]}
        spaceBetween={10}
        slidesPerView={1}
        navigation={{
          nextEl: ".image-slider-arrow-right",
          prevEl: ".image-slider-arrow-left",
        }}
        pagination={{
          clickable: true,
        }}
        loop={true}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className="image-container">
              <img src={image} alt={`Slide ${index + 1}`} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div>
        <div className="image-slider-arrow-left image-slider-arrow-btn">
          <img src={prevButton} alt="Previous" className="custom-btn" />
        </div>
        <div className="image-slider-arrow-right image-slider-arrow-btn">
          <img src={nextButton} alt="Next" className="custom-btn" />
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
