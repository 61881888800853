import React, { useEffect } from "react";
import { useParams, useLocation, NavLink } from "react-router-dom";
import projectsData, { getProjectById } from "../Projects/projectsData";
import "./project-detail.css";
import ImageSlider from "../../components/ImageSlider";
import MobileNav from "../../components/MobileNav";
import ProjectsSlider from "../../components/ProjectsSlider";
// import HomeTitle from "../../components/HomeTitle";

const ProjectDetail = () => {
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  const project = getProjectById(id);

  if (!project) {
    return <div>Project not found</div>;
  }

  const filteredProjects = projectsData.filter(
    (project) => project.id !== parseInt(id)
  );

  return (
    <>
      {window.innerWidth < 768 && <MobileNav />}

      <div className="project-detail">
        <div className="breadcrumb">
          <NavLink
            className={({ isActive }) => (isActive ? "breadcrumb-active" : "")}
            to="/"
          >
            Home
          </NavLink>
          <span>&gt;</span>
          <NavLink
            className={({ isActive }) => (isActive ? "breadcrumb-active" : "")}
            to="/projets"
          >
            Projects
          </NavLink>
          <span>&gt;</span>
          <NavLink
            className={({ isActive }) => (isActive ? "breadcrumb-active" : "")}
            to={`/projet/${id}`}
          >
            Details Projects
          </NavLink>
        </div>
        <div className="project-detail-content">
          <div className="project-images-vertical">
            {project.images.slice(0, 4).map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`${project.projectName} ${index + 1}`}
              />
            ))}
          </div>
          <div className="project-image-slider">
            <ImageSlider images={project.images} />
          </div>
          <div className="project-detail-text">
            <h1 className="project-title">Groupe ADDOHA</h1>
            <h4 className="project-sub-title">
              Nous avons pour mission de donner vie aux idées.
            </h4>
            <p className="project-description">
              Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
              posuere cubilia curae; Proin sodales ultrices nulla blandit
              volutpat. Vestibulum ante ipsum primis in faucibus orci luctus et
              ultrices posuere cubilia curae; Proin sodales ultrices nulla
              blandit.
            </p>
          </div>
        </div>
      </div>
      <div className="project-video">
        <iframe
          width="80%"
          height="560"
          src="https://www.youtube.com/embed/ZGRYZ6sNL1E?si=wQxH97uMn1c2HNAW"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
      <div className="projects-header">
        <h2>
          Autres-<span className="oranged_heading">Projets</span>
        </h2>
      </div>
      <ProjectsSlider projects={filteredProjects} />
    </>
  );
};

export default ProjectDetail;




/**
* details project with fetch
*/


// import React, { useEffect, useState } from "react";
// import { useParams, useLocation, NavLink } from "react-router-dom";
// import "./project-detail.css";
// import ImageSlider from "../../components/ImageSlider";
// import MobileNav from "../../components/MobileNav";
// import ProjectsSlider from "../../components/ProjectsSlider";

// const ProjectDetail = () => {
//   const { id } = useParams();
//   const location = useLocation();

//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   }, [location]);

//   const [projectsData, setProjectsData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const fetchProjects = async () => {
//     try {
//       const response = await fetch(`http://127.0.0.1:8000/api/v1/projects`); //use real API URL
//       if (!response.ok) {
//         throw new Error("Erreur lors de la récupération des projets.");
//       }
//       const data = await response.json();
//       setProjectsData(data); //or : data.items
//       setLoading(false);
//     } catch (err) {
//       setError(err.message);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchProjects();
//   }, [location]);

//   if (loading) return <div>Loading...</div>;

//   if (error) {
//     console.log(error);
//     return <div>{error}</div>;
//   }

//   const project = projectsData.find((project) => project._id === id);

//   if (!project) {
//     return <div>Project not found</div>;
//   }

//   const filteredProjects = projectsData.filter((proj) => proj._id !== id);

//   return (
//     <>
//       {window.innerWidth < 768 && <MobileNav />}

//       <div className="project-detail">
//         <div className="breadcrumb">
//           <NavLink
//             className={({ isActive }) => (isActive ? "breadcrumb-active" : "")}
//             to="/"
//           >
//             Home
//           </NavLink>
//           <span>&gt;</span>
//           <NavLink
//             className={({ isActive }) => (isActive ? "breadcrumb-active" : "")}
//             to="/projets"
//           >
//             Projects
//           </NavLink>
//           <span>&gt;</span>
//           <NavLink
//             className={({ isActive }) => (isActive ? "breadcrumb-active" : "")}
//             to={`/projet/${id}`}
//           >
//             Details Projects
//           </NavLink>
//         </div>
//         <div className="project-detail-content">
//           <div className="project-images-vertical">
//             {project.imagesUrls &&
//               project.imagesUrls.slice(0, 4).map((image, index) => (
//                 <img
//                   key={index}
//                   src={image}
//                   alt={`${project.titre} ${index + 1}`}
//                 />
//               ))}
//           </div>
//           <div className="project-image-slider">
//             <ImageSlider images={project.imagesUrls || []} />
//           </div>
//           <div className="project-detail-text">
//             <h1 className="project-title">{project.titre}</h1>
//             <h4 className="project-sub-title">{project.sousTitre}</h4>
//             <p className="project-description">{project.description}</p>
//           </div>
//         </div>
//       </div>
//       <div className="project-video">
//         <iframe
//           width="80%"
//           height="560"
//           src={project.urlVideo}
//           title="YouTube video player"
//           frameBorder="0"
//           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//           referrerPolicy="strict-origin-when-cross-origin"
//           allowFullScreen
//         ></iframe>
//       </div>
//       <div className="projects-header">
//         <h2>
//           Autres-<span className="oranged_heading">Projets</span>
//         </h2>
//       </div>
//       {/* <ProjectsSlider projects={filteredProjects} /> */}
//     </>
//   );
// };

// export default ProjectDetail;
