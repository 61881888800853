import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../pages/Contact/contact.css';

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      console.log(`${process.env.REACT_APP_BASE_URL}/api/contact`);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error("Erreur lors de l'envoi du message.");
      }

      setSuccessMessage('Message envoyé avec succès!');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          fullname: '',
          emailAddress: '',
          message: '',
        }}
        validationSchema={Yup.object({
          fullname: Yup.string().required('Le nom est obligatoire'),
          emailAddress: Yup.string().email('Adresse e-mail invalide').required("L'e-mail est obligatoire"),
          message: Yup.string().required('Le message est obligatoire'),
        })}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className="contact-form-group">
              <label htmlFor="fullname">Nom complet</label>
              <br />
              <Field
                type="text"
                name="fullname"
                placeholder="Entrez votre nom complet ici"
                className={`contact-form-field ${touched.fullname && errors.fullname ? 'contact-input-field-error' : ''}`}
              />
              <ErrorMessage name="fullname" component="span" className="error-message" />
            </div>
            <div className="contact-form-group">
              <label htmlFor="emailAddress">Adresse e-mail</label>
              <br />
              <Field
                type="email"
                name="emailAddress"
                placeholder="Entrez votre adresse e-mail ici"
                className={`contact-form-field ${touched.emailAddress && errors.emailAddress ? 'contact-input-field-error' : ''}`}
              />
              <ErrorMessage name="emailAddress" component="span" className="error-message" />
            </div>

            <div className="contact-form-group">
              <label htmlFor="message">Message</label>
              <br />
              <Field
                as="textarea"
                name="message"
                placeholder="Écrivez votre message ici"
                className={`contact-form-field contact-text-area ${touched.message && errors.message ? 'contact-input-field-error' : ''}`}
              />
              <ErrorMessage name="message" component="span" className="error-message" />
            </div>
            <button type="submit" disabled={isSubmitting || loading} className="contact-btn">
              {loading ? 'Envoi en cours...' : 'Envoyer le message'}
            </button>

            {error && <p style={{ color: 'red' }}>{error}</p>}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
