import React from "react";
import telephone from "../assets/socialIcons/phone.svg";
import instagram from "../assets/socialIcons/instagram.svg";
import facebook from "../assets/socialIcons/facebook.svg";
import localisation from "../assets/socialIcons/localisation.svg";
import "../pages/Home/home.css";

const SocialLinks = () => {
  return (
    <div className="header_socials">
      <a href="tel:+2126  61 76 37 72">
        <img src={telephone} alt="tele" />
      </a>
      <a
        href="https://www.instagram.com/sogerev.inter?igsh=Yng4eWlkdWsxeGZv"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={instagram} alt="insta" />
      </a>
      <a
        href="https://www.facebook.com/share/yzpPc7Pa54BAxtYn/?mibextid=qi2Omg"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={facebook} alt="fb" />
      </a>
      <a
        href="https://maps.app.goo.gl/XtphjYrEvMEf2xkE7"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={localisation} alt="geo" />
      </a>
    </div>
  );
};

export default SocialLinks;
