import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import prevButton from "../assets/Icons/slider-left-arrow.svg";
import nextButton from "../assets/Icons/slider-right-arrow.svg";
import "./CSS/productsSlider.css";
import Marbre from "../assets/Images/Marbre.png";
import Carlage from "../assets/Images/Carlage.png";
import Granite from "../assets/Images/granite.jpg";
import arrow from "../assets/shapesImages/marbreArrow.svg";

const slides = [
  {
    id: 1,
    image: Marbre,
    heading: "Marbre",
    paragraph: "Description of Product 1",
    number: "1",
  },
  {
    id: 2,
    image: Carlage,
    heading: "Carrelage",
    paragraph: "Description of Product 2",
    number: "2",
  },
  {
    id: 3,
    image: Granite,
    heading: "Granit",
    paragraph: "Description of Product 3",
    number: "3",
  },
];
const ProductsSlider = () => {
  return (
    <div className="wrapper">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        spaceBetween={10}
        slidesPerView={2}
        navigation={{
          nextEl: ".arrow-right",
          prevEl: ".arrow-left",
        }}
        pagination={{
          clickable: true,
        }}
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          769: {
            slidesPerView: 2,
          },
        }}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <div className="title_container">
              <Link to={`/produits?filter=${slide.heading}`}>
                <img src={arrow} className="marbre_arrow" alt="arrow"></img>
              </Link>
              <div className="shake">
                <h3> {slide.heading} </h3>
                <p>0{slide.number} — Sogerev INTER</p>
              </div>
            </div>
            <div className="image_container">
              <img src={slide.image} alt={slide.heading} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div>
        <div className="arrow-left arrow-btn">
          <img src={prevButton} />
        </div>
        <div className="arrow-right arrow-btn">
          <img src={nextButton} />
        </div>
      </div>
    </div>
  );
};

export default ProductsSlider;
