import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import "./CSS/mobileNav.css";
import logo from "../assets/Logo.png";

const MobileNav = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleButtonClick = () => {
    navigate("/contact");
  };

  return (
    <div className="mobile-nav">
      <button className="button" onClick={handleButtonClick}>
        Demander un devis
      </button>
      <div className="logo-hamburger">
        <div className="logo" onClick={handleLogoClick}>
          <img src={logo} alt="Logo" />
        </div>
        <div className="hamburger-icon" onClick={toggleMenu}>
          &#9776;
        </div>
      </div>

      {isMenuOpen && (
        <div className="mobile-menu">
          <ul>
            <li>
              <NavLink
                onClick={toggleMenu}
                to="/"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Accueil
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                À propos
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/produits"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Produits
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/?location=projets"
                className={({ isActive }) => (isActive ? "" : "")}
              >
                Projets
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/Catalogue.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                catalogue
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Contactez-nous
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MobileNav;
