import React from "react";
import icon from "../assets/Icons/Rectangle.svg";
const HomeTitle = ({ tit, le }) => {
  return (
    <div className="home_title">
      <img src={icon} alt="rectangle" className="rectangle" />
      <h2>
        {tit} <span className="underline">{le}</span>
      </h2>
    </div>
  );
};

export default HomeTitle;
