import React from 'react';
import './CSS/singleProduct.css';

const SingleProduct = ({ name, image, price, promotionalPrice }) => {
  const discountPercentage = promotionalPrice ? Math.round(((price - promotionalPrice) / price) * 100) : null;

  return (
    <div className="product-card">
      <img src={`${process.env.REACT_APP_BASE_URL}${image}`} alt={name} className="product-image" />
      {promotionalPrice && <span className="discount-badge">-{discountPercentage}%</span>}
      <div className="product-details">
        <h3 className="product-name">{name}</h3>
        {price !== undefined && price !== null ? (
          <div className="product-price">
            {promotionalPrice ? (
              <div>
                <span className="original-price">{price.toFixed(2)}DH</span>
                <span className="promotional-price">{promotionalPrice.toFixed(2)}DH</span>
              </div>
            ) : (
              <p className="product-price">{price.toFixed(2)}DH</p>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default SingleProduct;
