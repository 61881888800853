import React, { useEffect, useState } from 'react';
import MobileNav from '../../components/MobileNav';
import TitlePageHolder from '../../components/TitlePageHolder';
import SingleProduct from '../../components/SingleProduct';
import './products.css';
import { useSearchParams } from 'react-router-dom';

const Products = () => {
  const [searchParams] = useSearchParams();
  const initialCategory = searchParams.get('filter') || 'Marbre';
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [visibleProducts, setVisibleProducts] = useState(9);
  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const categories = [
    { id: 1, name: 'Marbre' },
    { id: 3, name: 'Carrelage' },
    { id: 2, name: 'Granit' },
  ];

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/products`);
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des produits.');
      }
      const data = await response.json();
      setProductsData(data.items);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
    window.scrollTo(0, 0);
  }, []);

  const handleCategorySelect = category => {
    setSelectedCategory(category.name);
    setVisibleProducts(9);
  };

  const filteredProducts = productsData.filter(product => product.category.name === selectedCategory);

  const handleShowMore = () => {
    setVisibleProducts(prevVisibleProducts => prevVisibleProducts + 9);
  };

  if (loading) return <div>Loading...</div>;

  if (error) {
    console.log(error);
  }

  return (
    <>
      <div>
        {window.innerWidth < 768 && <MobileNav />}
        <TitlePageHolder
          title="NOS PRODUITS"
          paragraph="La qualité qui transcende les frontières, sogerev vous offre le monde en revêtements."
        />
      </div>
      <div className="categories-filter">
        {categories.map((category, index) => (
          <button key={index} onClick={() => handleCategorySelect(category)} className={selectedCategory === category.name ? 'active' : ''}>
            {category.name}
          </button>
        ))}
      </div>
      <div className="products-container">
        {filteredProducts.slice(0, visibleProducts).map(product => (
          <SingleProduct
            name={product.name}
            image={product.imageUrl}
            price={selectedCategory === 'Carrelage' ? product.sellPriceAfterTax : null}
            promotionalPrice={selectedCategory === 'Carrelage' ? product.prixPromo : null}
          />
        ))}
      </div>
      {filteredProducts.length > visibleProducts && (
        <div className="show-more-container">
          <button className="show-more-button" onClick={handleShowMore}>
            Voir plus
          </button>
        </div>
      )}
    </>
  );
};

export default Products;
