import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import logo from "../assets/Logo.png";

const Navbar = () => {
  const navigate = useNavigate();
  const [animationDone, setAnimationDone] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationDone(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <nav className="navbar">
      <Link to="/">
        <motion.div
          className="logo-container"
          initial={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          animate={{
            backgroundColor: "transparent",
            position: "relative",
            width: "auto",
            height: "auto",
          }}
          transition={{ duration: 2, ease: "easeInOut" }}
          style={{
            position: animationDone ? "relative" : "absolute",
            zIndex: 999,
          }}
        >
          <motion.img
            src={logo}
            alt="Logo"
            initial={{
              scale: 6,
            }}
            animate={{
              scale: 1,
            }}
            transition={{ duration: 2, ease: "easeInOut" }}
          />
        </motion.div>
      </Link>
      <ul>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "active-item" : "")}
            to="/"
          >
            Accueil
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "active-item" : "")}
            to="/about"
          >
            À propos
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "active-item" : "")}
            to="/produits"
          >
            Produits
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            className={({ isActive }) => (isActive ? "" : "")}
            to="/?location=projets"
          >
            Projets
          </NavLink>
        </li> */}
        <li>
          <NavLink
            to="/Catalogue.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            catalogue
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "active-item" : "")}
            to="/contact"
          >
            Contactez-nous
          </NavLink>
        </li>
      </ul>
      <button className="btn-primary" onClick={() => navigate("/contact")}>
        DEMANDER UN DEVIS
      </button>
    </nav>
  );
};

export default Navbar;
