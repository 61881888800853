import React from "react";
import "./CSS/projectsSlider.css";
import prevButton from "../assets/Icons/slider-left-arrow.svg";
import nextButton from "../assets/Icons/slider-right-arrow.svg";
import { Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

const ProjectsSlider = ({ projects }) => {
  return (
    <div className="swiper-container">
      <Swiper
        modules={[Navigation, Scrollbar]}
        spaceBetween={10}
        slidesPerView={4}
        navigation={{
          nextEl: ".arrow-next",
          prevEl: ".arrow-prev",
        }}
        scrollbar={{
          el: ".scrollbar",
          draggable: true,
          dragSize: "auto",
          snapOnRelease: true,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          769: {
            slidesPerView: 4,
          },
        }}
      >
        {projects.map((project) => (
          <SwiperSlide key={project.id}>
            <div className="slide-content">
              <img
                src={project.images[0]}
                alt={`${project.projectName} - Cover`}
              />
              <div className="over-lay">
                <div className="city">{project.city}</div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-buttons">
        <div className="arrow-prev arrow">
          <img src={prevButton} />
        </div>
        <div className="arrow-next arrow">
          <img src={nextButton} />
        </div>
      </div>
      <div className="scrollbar"></div>
    </div>
  );
};
export default ProjectsSlider;


/**
* code if using fetch
*/

// import React from "react";
// import { useNavigate } from "react-router-dom";
// import "./CSS/projectsSlider.css";
// import prevButton from "../assets/Icons/slider-left-arrow.svg";
// import nextButton from "../assets/Icons/slider-right-arrow.svg";
// import projectArrow from "../assets/Icons/project-arrow.svg";
// import { Navigation, Scrollbar } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/scrollbar";

// const ProjectsSlider = ({ projects }) => {
//   console.log(projects);

//   const navigate = useNavigate();
//   const handleNavigate = (projectId) => {
//     // Heeeeeeeeeeeeeeeeeeeeere let's implement navigation logic
//     // console.log(`Navigate to project with id: ${projectId}`);
//     navigate(`/projet/${projectId}`);
//   };

//   return (
//     <div className="swiper-container">
//       <Swiper
//         modules={[Navigation, Scrollbar]}
//         spaceBetween={10}
//         slidesPerView={4}
//         navigation={{
//           nextEl: ".arrow-next",
//           prevEl: ".arrow-prev",
//         }}
//         scrollbar={{
//           el: ".scrollbar",
//           draggable: true,
//           dragSize: "auto",
//           snapOnRelease: true,
//         }}
//         breakpoints={{
//           320: {
//             slidesPerView: 1,
//           },
//           769: {
//             slidesPerView: 4,
//           },
//         }}
//       >
//         {projects.map(
//           (
//             project //Or projects.items.map
//           ) => (
//             <SwiperSlide key={project._id}>
//               <div className="slide-content">
//                 <img
//                   src={project.imagesUrls[0]}
//                   alt={`${project.title} - Cover`}
//                 />
//                 <div className="over-lay">
//                   <div className="city">{project.ville}</div>
//                   <img
//                     src={projectArrow}
//                     alt="Navigate"
//                     className="navigate-arrow"
//                     onClick={() => handleNavigate(project.id)}
//                   />
//                 </div>
//               </div>
//             </SwiperSlide>
//           )
//         )}
//       </Swiper>
//       <div className="swiper-buttons">
//         <div className="arrow-prev arrow">
//           <img src={prevButton} />
//         </div>
//         <div className="arrow-next arrow">
//           <img src={nextButton} />
//         </div>
//       </div>
//       <div className="scrollbar"></div>
//     </div>
//   );
// };

// export default ProjectsSlider;
