import React from "react";
import { useNavigate } from "react-router-dom";
import "../pages/About/about.css";
import Rectangle from "../assets/shapesImages/Rectangle.png";
const GreyCard = () => {
  const navigate = useNavigate();
  return (
    <div className="grey-card">
      <img src={Rectangle} className="card-rectangle" />
      <h3>Découvrez l'équipe passionnée de SOGEREV</h3>
      <p>
        Découvrez l'équipe passionnée de SOGEREV, experts en carrelages,
        marbres, granits et pierres naturelles de qualité, offrant beauté et
        élégance.
      </p>
      <button onClick={() => navigate("/contact")} className="btn-secondary">
        Contactez-nous
      </button>
    </div>
  );
};

export default GreyCard;
