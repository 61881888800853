import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TitlePageHolder from "../../components/TitlePageHolder";
import "./about.css";
import AboutFirstSec from "../../assets/Images/AboutFirstSec.png";
import AboutHistory from "../../assets/Images/AboutHistory.png";
import AboutCard from "../../components/AboutCard";
import InnovationIcon from "../../assets/Icons/InnovationIcon.svg";
import ProfessionalismIcon from "../../assets/Icons/ProfessionalismIcon.svg";
import EquipeIcon from "../../assets/Icons/EquipeIcon.svg";
import QualiteIcon from "../../assets/Icons/QualiteIcon.svg";
import FiabiliteIcon from "../../assets/Icons/FiabiliteIcon.svg";
import EngagementIcon from "../../assets/Icons/EngagementIcon.svg";
import Cube from "../../assets/shapesImages/Cube.png";
import Tube from "../../assets/shapesImages/Tube.png";
import Cubic from "../../assets/shapesImages/Cubic.png";
import VideoRectangle from "../../assets/shapesImages/Rectangle-video.png";
import AboutBackground from "../../assets/backgroundImages/AboutBackground.jpg";
import Rectangle from "../../assets/shapesImages/Rectangle.png";
import GreyCard from "../../components/GreyCard";
import MobileNav from "../../components/MobileNav";
const About = () => {
  const navigate = useNavigate();

  useEffect(() => window.scrollTo(0, 0), []);

  const quiSommesNousRef = useRef(null);
  const notreHistoireRef = useRef(null);
  const nosValeursRef = useRef(null);
  const notreTeamRef = useRef(null);

  const handleScroll = (param) => {
    param.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {window.innerWidth < 768 && <MobileNav />}
      <div className="about_wrapper">
        <TitlePageHolder
          title="À PROPOS DE NOUS"
          paragraph="La qualité qui transcende les frontières, sogerev vous offre le monde en revêtements."
        />

        <ul className="breadcrumbs">
          <li className="item" onClick={() => handleScroll(quiSommesNousRef)}>
            qui sommes-nous
          </li>
          <li className="item" onClick={() => handleScroll(notreHistoireRef)}>
            notre histoire
          </li>
          <li className="item" onClick={() => handleScroll(nosValeursRef)}>
            nos valeurs
          </li>
          <li className="item" onClick={() => handleScroll(notreTeamRef)}>
            notre team
          </li>
        </ul>

        <div className="about_first_sec">
          <h2 ref={quiSommesNousRef}>
            Qui Sommes-<span className="oranged_heading">NOUS.</span>
          </h2>
          <div className="paragraph_wrapper">
            <p className="centered_paragraph">
              Chez SOGEREV, nous sommes bien plus qu'une entreprise de
              revêtement. Notre équipe dévouée et passionnée vous offre qualité,
              service client et satisfaction totale. Faites-nous confiance pour
              tous vos projets résidentiels ou commerciaux.
            </p>
          </div>
          <div className="about_first_sec_content">
            <div className="about_first_sec_image">
              <img src={AboutFirstSec} />
            </div>
            <div className="about_first_sec_text">
              <h3>
                Découvrez SOGEREV - Votre Partenaire pour des Revêtements
                d'Exception
              </h3>
              <p>
                Nous comprenons que chaque projet, qu'il soit résidentiel ou
                commercial, est unique. C'est pourquoi nous mettons à profit
                notre expertise et notre dévouement pour offrir des solutions
                sur mesure qui répondent parfaitement aux besoins et aux
                attentes de nos clients. Notre objectif est de devenir votre
                partenaire de confiance dans tous vos projets de revêtement, en
                vous offrant un soutien constant depuis la conception jusqu'à la
                réalisation, garantissant ainsi des résultats exceptionnels à
                chaque étape du processus.
              </p>
              {/* <hr />
              <p>
                Would you explore nature paradise in the world, let’s find the
                best destination in world with us.
              </p> */}
              <button
                onClick={() => navigate("/contact")}
                className="btn-primary"
              >
                Contactez-nous
              </button>
            </div>
          </div>
          <div className="about-image-container">
            <img src={AboutBackground} className="background-image" />
            <div className="text-overlay">
              <h2>Tout Faire Soi-Même Est Difficile.</h2>
              <p>
                La qualité qui transcende les frontières SOGREV vous offre le
                monde en revêtements d'exception.
              </p>
            </div>
            <div className="about-video">
              <iframe
                width="980" //560
                height="400" //315
                src="https://www.youtube.com/embed/-PWJwUGZ94E"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
              {/* <img src={Rectangle} alt="Rectangle" className="video-rectangle" /> */}
            </div>
          </div>
        </div>
        <div className="about_second_sec" ref={notreHistoireRef}>
          <img src={Cubic} className="cubic" />
          <h2>
            Notre <span className="oranged_heading">Histoire.</span>
          </h2>
          <div className="paragraph_wrapper">
            <p className="centered_paragraph">
              Chez SOGEREV, notre histoire est celle d'un engagement passionné
              envers l'excellence et l'innovation. De nos débuts modestes à
              notre position de leader, nous nous distinguons par notre
              dévouement à la qualité et à la satisfaction client. Chaque projet
              est une chance de créer des espaces inspirants.
            </p>
          </div>
          <div className="about_second_sec_content">
            <div className="about_second_sec_image">
              <img src={AboutHistory} />
            </div>
            <div className="about_second_sec_text">
              <h3>COMMENT NOUS COMMENÇONS</h3>
              <p>
                Notre histoire chez SOGEREV est celle d'un voyage dédié à
                transformer des espaces avec des revêtements exceptionnels.
                Depuis nos débuts modestes, nous sommes devenus un leader de
                l'industrie grâce à notre engagement envers la qualité et la
                satisfaction client. Notre passion pour l'excellence et
                l'innovation continue de guider notre évolution.
              </p>
            </div>
          </div>
        </div>
        <div className="about_third_sec" ref={nosValeursRef}>
          <img src={Cube} className="cube" />
          <img src={Tube} className="tube" />
          <h2>
            Nos <span className="oranged_heading">Valeurs.</span>
          </h2>
          <div className="paragraph_wrapper">
            <p className="centered_paragraph">
              La qualité représente notre engagement à offrir des produits et
              services de haute performance, l'innovation guide notre recherche
              constante de nouvelles solutions, et notre fiabilité assure que
              nos clients peuvent compter sur nous à chaque étape.
            </p>
          </div>
          <div className="cards_container">
            <AboutCard
              icon={InnovationIcon}
              heading="Innovation"
              text="Notre culture d'innovation alimente notre quête incessante de solutions novatrices"
            />
            <AboutCard
              icon={FiabiliteIcon}
              heading="Fiabilité"
              text="Notre fiabilité inébranlable est le pilier sur lequel nos clients peuvent toujours compter"
            />
          </div>
          <div className="cards_container">
            <AboutCard
              icon={QualiteIcon}
              heading="Qualité "
              text="Produits et services de premier ordre à chaque étape de notre parcours"
            />
            <AboutCard
              icon={EngagementIcon}
              heading="Engagement"
              text="Notre engagement inébranlable se reflète dans chaque interaction avec nos clients"
            />
          </div>
        </div>
        <div className="about_fourth_sec" ref={notreTeamRef}>
          <h2>
            Notre <span className="oranged_heading">Team.</span>
          </h2>
          <div className="paragraph_wrapper">
            <p className="centered_paragraph">
              Explorez le groupe SOGEREV, une équipe dédiée spécialisée dans les
              carrelages, marbres, granits et pierres naturelles de premier
              choix, apportant raffinement et sophistication à chaque projet.
            </p>
          </div>
          <div className="notre_team">
            <iframe
              width="700" //560
              height="560" //315
              src="https://www.youtube.com/embed/D0H-vFQO4-A"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
            <div className="card-position">
              <GreyCard />
            </div>
            <div className="video-rectangle-position">
              <img src={VideoRectangle} />
            </div>
          </div>
          {window.innerWidth < 768 && (
            <div className="notre_team_mobile">
              <iframe
                width="80%" //560
                height="560" //315
                src="https://www.youtube.com/embed/D0H-vFQO4-A"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default About;
