import React from "react";
import TitleImage from "../assets/backgroundImages/TitleImage.png";
import "./CSS/titlePageHolder.css";
const TitlePageHolder = ({ title, paragraph }) => {
  return (
    <div className="title_wrapper">
      <img src={TitleImage} className="title_image" />
      <div className="text-container">
        <h2 className="title_text">{title} </h2>
        <p className="paragraph_text">{paragraph}</p>
      </div>
    </div>
  );
};

export default TitlePageHolder;
