import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Products from "./pages/Products/Products";
import Projects from "./pages/Projects/Projects";
import Contact from "./pages/Contact/Contact";
import SocialLinks from "./components/SocialLinks";
import ProjectDetail from "./pages/ProjectDetail/ProjectDetail";
import { logPageView } from "./utils/analytics";

function App() {
  
  useEffect(() => {
    logPageView();
    const handleRouteChange = () => logPageView();
    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  return (
    <div>
      <Router>
        <Navbar />
        <SocialLinks />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/produits" element={<Products />} />
          {/* <Route path="/projets" element={<Projects />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/projet/:id" element={<ProjectDetail />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
