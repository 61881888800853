import React from "react";

const HeaderCard = ({ icon, number, text }) => {
  return (
    <div className="header_card">
      <img src={icon} alt="Header Icon" className="header_logo" />
      <p className="header_numbers">{number}</p>
      <p className="header_paragraph">{text}</p>
    </div>
  );
};

export default HeaderCard;
