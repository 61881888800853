import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import './CSS/footerContactForm.css';
import rectangle from '../assets/shapesImages/footerRectangle.png';

const FooterContactForm = () => {
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fullname: values.fullName,
          emailAddress: values.email,
          message: values.message,
        }),
      });

      if (!response.ok) {
        throw new Error("Erreur lors de l'envoi du message.");
      }

      setSuccessMessage('Message envoyé avec succès!');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
      resetForm();
    }
  };
  return (
    <>
      {' '}
      <Formik
        initialValues={{
          fullName: '',
          email: '',
          phoneNumber: '',
          message: '',
        }}
        validationSchema={Yup.object({
          fullName: Yup.string().required('Le nom est obligatoire'),
          email: Yup.string().email('Adresse e-mail invalide').required("L'e-mail est obligatoire"),
          phoneNumber: Yup.string().required('Le numéro de téléphone est obligatoire'),
          message: Yup.string().required('Le message est obligatoire'),
        })}
        onSubmit={(values, { resetForm, setErrors, setTouched }) => {
          const errors = {};

          if (!values.fullName) errors.fullName = 'Le nom est obligatoire';
          if (!values.email) errors.email = "L'e-mail est obligatoire";
          if (!values.phoneNumber) errors.phoneNumber = 'Le numéro de téléphone est obligatoire';
          if (!values.message) errors.message = 'Le message est obligatoire';

          if (Object.keys(errors).length > 0) {
            setErrors(errors);
          } else {
            console.log(values);
            resetForm();
            setSuccessMessage('Formulaire soumis avec succès !');
          }

          setTouched({
            fullName: true,
            email: true,
            phoneNumber: true,
            message: true,
          });
        }}
      >
        {({ errors, touched }) => (
          <Form className="footer-contact-form">
            <div style={{ color: 'red' }}>
              {Object.keys(errors).length > 1 ? (
                <div>Tous les champs sont obligatoires.</div>
              ) : (
                <>
                  {touched.fullName && errors.fullName && <div className="error-message">{errors.fullName}</div>}
                  {touched.email && errors.email && <div className="error-message">{errors.email}</div>}
                  {touched.phoneNumber && errors.phoneNumber && <div className="error-message">{errors.phoneNumber}</div>}
                  {touched.message && errors.message && <div className="error-message">{errors.message}</div>}
                </>
              )}
            </div>

            {successMessage && (
              <div
                style={{
                  color: 'green',
                  marginBottom: '10px',
                  fontWeight: 600,
                }}
              >
                {successMessage}
              </div>
            )}

            <img src={rectangle} className="footer_rectangle" alt="rectangle" />
            <h2>Contactez-Nous</h2>

            <Field
              type="text"
              id="fullName"
              name="fullName"
              placeholder="Nom et Prénom"
              className={`input-field ${touched.fullName && errors.fullName ? 'input-field-error' : ''}`}
            />

            <Field
              type="email"
              id="email"
              name="email"
              placeholder="E-mail"
              className={`input-field ${touched.email && errors.email ? 'input-field-error' : ''}`}
            />

            <Field
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Numéro de téléphone"
              className={`input-field ${touched.phoneNumber && errors.phoneNumber ? 'input-field-error' : ''}`}
            />

            <Field
              as="textarea"
              id="message"
              name="message"
              placeholder="Votre message"
              className={`input-field ${touched.message && errors.message ? 'input-field-error' : ''}`}
            />

            <button type="submit" className="footer-btn">
              ENVOYER
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FooterContactForm;
