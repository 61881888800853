const projectsData = [
  {
    id: 1,
    projectName: "Project 1",
    projectDescription: "Lorem ipsum",
    city: "Tanger",
    category: "Marbre",
    images: [
      require("../../assets/Images/project1.png"),
      require("../../assets/Images/project2.png"),
      require("../../assets/Images/project3.png"),
      require("../../assets/Images/project4.png"),
    ],
  },
  {
    id: 2,
    projectName: "Project 2",
    projectDescription: "Lorem ipsum",
    city: "Rabat",
    category: "Marbre",
    images: [
      require("../../assets/Images/project2.png"),
      require("../../assets/Images/project3.png"),
      require("../../assets/Images/project4.png"),
      require("../../assets/Images/project5.png"),
    ],
  },
  {
    id: 3,
    projectName: "Project 3",
    projectDescription: "Lorem ipsum",
    city: "Oujda",
    category: "Carlage",
    images: [
      require("../../assets/Images/project3.png"),
      require("../../assets/Images/project4.png"),
      require("../../assets/Images/project5.png"),
      require("../../assets/Images/project6.png"),
    ],
  },
  {
    id: 4,
    projectName: "Project 4",
    projectDescription: "Lorem ipsum",
    city: "Casablanca",
    category: "Carlage",
    images: [
      require("../../assets/Images/project4.png"),
      require("../../assets/Images/project5.png"),
      require("../../assets/Images/project6.png"),
      require("../../assets/Images/project7.png"),
    ],
  },
  {
    id: 5,
    projectName: "Project 5",
    projectDescription: "Lorem ipsum",
    city: "Tanger",
    category: "Granite",
    images: [
      require("../../assets/Images/project5.png"),
      require("../../assets/Images/project6.png"),
      require("../../assets/Images/project7.png"),
      require("../../assets/Images/project8.png"),
    ],
  },
  {
    id: 6,
    projectName: "Project 6",
    projectDescription: "Lorem ipsum",
    city: "Rabat",
    category: "Granite",
    images: [
      require("../../assets/Images/project6.png"),
      require("../../assets/Images/project7.png"),
      require("../../assets/Images/project8.png"),
      require("../../assets/Images/project1.png"),
    ],
  },
  {
    id: 7,
    projectName: "Project 7",
    projectDescription: "Lorem ipsum",
    city: "Oujda",
    category: "Marbre",
    images: [
      require("../../assets/Images/project7.png"),
      require("../../assets/Images/project8.png"),
      require("../../assets/Images/project1.png"),
      require("../../assets/Images/project2.png"),
    ],
  },
  {
    id: 8,
    projectName: "Project 8",
    projectDescription: "Lorem ipsum",
    city: "Casablanca",
    category: "Marbre",
    images: [
      require("../../assets/Images/project8.png"),
      require("../../assets/Images/project1.png"),
      require("../../assets/Images/project2.png"),
      require("../../assets/Images/project3.png"),
    ],
  },
  {
    id: 9,
    projectName: "Project 9",
    projectDescription: "Lorem ipsum",
    city: "Tanger",
    category: "Carlage",
    images: [
      require("../../assets/Images/project1.png"),
      require("../../assets/Images/project2.png"),
      require("../../assets/Images/project3.png"),
      require("../../assets/Images/project4.png"),
    ],
  },
  {
    id: 10,
    projectName: "Project 10",
    projectDescription: "Lorem ipsum",
    city: "Rabat",
    category: "Carlage",
    images: [
      require("../../assets/Images/project3.png"),
      require("../../assets/Images/project4.png"),
      require("../../assets/Images/project5.png"),
      require("../../assets/Images/project6.png"),
    ],
  },
  {
    id: 11,
    projectName: "Project 11",
    projectDescription: "Lorem ipsum",
    city: "Oujda",
    category: "Granite",
    images: [
      require("../../assets/Images/project5.png"),
      require("../../assets/Images/project6.png"),
      require("../../assets/Images/project7.png"),
      require("../../assets/Images/project8.png"),
    ],
  },
  {
    id: 12,
    projectName: "Project 12",
    projectDescription: "Lorem ipsum",
    city: "Casablanca",
    category: "Granite",
    images: [
      require("../../assets/Images/project7.png"),
      require("../../assets/Images/project8.png"),
      require("../../assets/Images/project1.png"),
      require("../../assets/Images/project2.png"),
    ],
  },
  {
    id: 13,
    projectName: "Project 13",
    projectDescription: "Lorem ipsum",
    city: "Tanger",
    category: "Marbre",
    images: [
      require("../../assets/Images/project2.png"),
      require("../../assets/Images/project3.png"),
      require("../../assets/Images/project4.png"),
      require("../../assets/Images/project5.png"),
    ],
  },
  {
    id: 14,
    projectName: "Project 14",
    projectDescription: "Lorem ipsum",
    city: "Rabat",
    category: "Marbre",
    images: [
      require("../../assets/Images/project4.png"),
      require("../../assets/Images/project5.png"),
      require("../../assets/Images/project6.png"),
      require("../../assets/Images/project7.png"),
    ],
  },
  {
    id: 15,
    projectName: "Project 15",
    projectDescription: "Lorem ipsum",
    city: "Oujda",
    category: "Carlage",
    images: [
      require("../../assets/Images/project6.png"),
      require("../../assets/Images/project7.png"),
      require("../../assets/Images/project8.png"),
      require("../../assets/Images/project1.png"),
    ],
  },
  {
    id: 16,
    projectName: "Project 16",
    projectDescription: "Lorem ipsum",
    city: "Casablanca",
    category: "Carlage",
    images: [
      require("../../assets/Images/project8.png"),
      require("../../assets/Images/project1.png"),
      require("../../assets/Images/project2.png"),
      require("../../assets/Images/project3.png"),
    ],
  },
  {
    id: 17,
    projectName: "Project 17",
    projectDescription: "Lorem ipsum",
    city: "Tanger",
    category: "Granite",
    images: [
      require("../../assets/Images/project1.png"),
      require("../../assets/Images/project2.png"),
      require("../../assets/Images/project3.png"),
      require("../../assets/Images/project4.png"),
    ],
  },
  {
    id: 18,
    projectName: "Project 18",
    projectDescription: "Lorem ipsum",
    city: "Rabat",
    category: "Granite",
    images: [
      require("../../assets/Images/project2.png"),
      require("../../assets/Images/project3.png"),
      require("../../assets/Images/project4.png"),
      require("../../assets/Images/project5.png"),
    ],
  },
  {
    id: 19,
    projectName: "Project 19",
    projectDescription: "Lorem ipsum",
    city: "Oujda",
    category: "Marbre",
    images: [
      require("../../assets/Images/project3.png"),
      require("../../assets/Images/project4.png"),
      require("../../assets/Images/project5.png"),
      require("../../assets/Images/project6.png"),
    ],
  },
  {
    id: 20,
    projectName: "Project 20",
    projectDescription: "Lorem ipsum",
    city: "Casablanca",
    category: "Marbre",
    images: [
      require("../../assets/Images/project4.png"),
      require("../../assets/Images/project5.png"),
      require("../../assets/Images/project6.png"),
      require("../../assets/Images/project7.png"),
    ],
  },
  {
    id: 21,
    projectName: "Project 21",
    projectDescription: "Lorem ipsum",
    city: "Tanger",
    category: "Marbre",
    images: [
      require("../../assets/Images/project5.png"),
      require("../../assets/Images/project6.png"),
      require("../../assets/Images/project7.png"),
      require("../../assets/Images/project8.png"),
    ],
  },
  {
    id: 22,
    projectName: "Project 22",
    projectDescription: "Lorem ipsum",
    city: "Rabat",
    category: "Marbre",
    images: [
      require("../../assets/Images/project6.png"),
      require("../../assets/Images/project7.png"),
      require("../../assets/Images/project8.png"),
      require("../../assets/Images/project1.png"),
    ],
  },
  {
    id: 23,
    projectName: "Project 23",
    projectDescription: "Lorem ipsum",
    city: "Oujda",
    category: "Carlage",
    images: [
      require("../../assets/Images/project7.png"),
      require("../../assets/Images/project8.png"),
      require("../../assets/Images/project1.png"),
      require("../../assets/Images/project2.png"),
    ],
  },
  {
    id: 24,
    projectName: "Project 24",
    projectDescription: "Lorem ipsum",
    city: "Casablanca",
    category: "Carlage",
    images: [
      require("../../assets/Images/project8.png"),
      require("../../assets/Images/project1.png"),
      require("../../assets/Images/project2.png"),
      require("../../assets/Images/project3.png"),
    ],
  },
  {
    id: 25,
    projectName: "Project 25",
    projectDescription: "Lorem ipsum",
    city: "Tanger",
    category: "Granite",
    images: [
      require("../../assets/Images/project1.png"),
      require("../../assets/Images/project2.png"),
      require("../../assets/Images/project3.png"),
      require("../../assets/Images/project4.png"),
    ],
  },
  {
    id: 26,
    projectName: "Project 26",
    projectDescription: "Lorem ipsum",
    city: "Rabat",
    category: "Granite",
    images: [
      require("../../assets/Images/project2.png"),
      require("../../assets/Images/project3.png"),
      require("../../assets/Images/project4.png"),
      require("../../assets/Images/project5.png"),
    ],
  },
  {
    id: 27,
    projectName: "Project 27",
    projectDescription: "Lorem ipsum",
    city: "Tanger",
    category: "Marbre",
    images: [
      require("../../assets/Images/project6.png"),
      require("../../assets/Images/project7.png"),
      require("../../assets/Images/project8.png"),
      require("../../assets/Images/project1.png"),
    ],
  },
];

export const getProjectById = (id) => {
  return projectsData.find((project) => project.id === parseInt(id));
};

export default projectsData;
