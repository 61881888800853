import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./home.css";
import HeaderCard from "../../components/HeaderCard";
import icon1 from "../../assets/Icons/Icon1.svg";
import icon2 from "../../assets/Icons/Icon2.svg";
import icon3 from "../../assets/Icons/Icon3.svg";
import HomeTitle from "../../components/HomeTitle";
import image1 from "../../assets/Images/Image1.png";
import image2 from "../../assets/Images/Image2.png";
import image3 from "../../assets/Images/Image3.png";
import HeaderBadge from "../../components/HeaderBadge";
import badgeicon1 from "../../assets/Icons/BadgeIcon1.svg";
import badgeicon2 from "../../assets/Icons/BadgeIcon2.svg";
// import arrow from "../../assets/shapesImages/marbreArrow.svg";
import ProjectsSlider from "../../components/ProjectsSlider";
import ProductsSlider from "../../components/ProductsSlider";
import MobileNav from "../../components/MobileNav";
import projectsData from "../Projects/projectsData";
const Home = () => {
  const navigate = useNavigate();

  // sroll to projets slider
  const [searchParams] = useSearchParams();
  const projectsSliderRef = useRef(null);
  const location = searchParams.get("location");
  useEffect(() => {
    if (location === "projets") {
      projectsSliderRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  /**
   * Fetch projects data
   */
  // const [projectsData, setProjectsData] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  // const fetchProjects = async () => {
  //   try {
  //     const response = await fetch(`http://127.0.0.1:8000/api/v1/projects`); //use real API URL
  //     if (!response.ok) {
  //       throw new Error("Erreur lors de la récupération des projets.");
  //     }
  //     const data = await response.json();
  //     setProjectsData(data); //or : data.items
  //     setLoading(false);
  //   } catch (err) {
  //     setError(err.message);
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchProjects();
  // }, [location]);

  // if (loading) return <div>Loading...</div>;

  // if (error) {
  //   console.log(error);
  //   return <div>{error}</div>;
  // }

  return (
    <>
      {window.innerWidth < 768 && <MobileNav />}
      <div className="header">
        <div className="header_text">
          <div className="header_main_text">
            <h1>SOCIÉTÉ GENÉRALE DE REVÊTEMENT</h1>
          </div>
          <p>
            La qualité qui transcende les frontières,
            <span className="oranged-text">sogerev</span> vous offre le monde en
            revêtements.
          </p>
          {/* <hr className="horizontal-line" /> */}
        </div>
        <div className="home-video">
          <iframe
            width="80%" //560
            height="560" //315
            src="https://www.youtube.com/embed/cWbzJIS4-pg"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
          <div className="header_cards">
            <HeaderCard icon={icon1} number="326+" text="clients satisfaits" />
            <HeaderCard icon={icon2} number="714+" text="unités installées" />
            <HeaderCard icon={icon3} number="34+" text="proffesional" />
          </div>
        </div>
      </div>
      <div className="first_sec_margin">
        <HomeTitle tit="Qui Sommes-" le="Nous?" />
        <div className="first_section">
          <div className="first_sec_images">
            <img src={image1}></img>
            <div className="horizontal_images">
              <img src={image2}></img>
              <img src={image3}></img>
            </div>
          </div>
          <div className="first_sec_content">
            <h2>
              Découvrez SOGEREV - Votre Partenaire pour des Revêtements
              d'Exception
            </h2>
            <p>
              Nous importons des matériaux de qualité supérieure du monde entier
              pour vous offrir une sélection variée de carrelages, marbres,
              granites et pierres naturelles. Faites confiance à notre expertise
              pour transformer votre espace en un lieu d'élégance intemporel
            </p>
            <hr />
            <HeaderBadge
              imgSrc={badgeicon1}
              headingText="Qualité"
              paragraphText="Nous garantissons une qualité exceptionnelle en nous engageant à dépasser les attentes de nos clients à chaque étape."
            />
            <HeaderBadge
              imgSrc={badgeicon2}
              headingText="Innovation "
              paragraphText="Nous cultivons l'innovation en encourageant la créativité et en investissant dans la recherche pour rester à la pointe des nouvelles tendances."
            />
            <button
              className="btn-secondary"
              onClick={() => navigate("/about")}
            >
              En savoir plus sur nous
            </button>
          </div>
        </div>
      </div>
      <HomeTitle tit="Ce que nous" le="offrons" />
      <div className="second_section">
        <div className="second_sec_content">
          <h2>NOS PRODUITS</h2>
          <hr />
          <h3>Des projets de revêtements concrets et de qualité</h3>
          <p>
            Notre offre comprend une gamme complète de services, de la
            consultation à l'installation, assurant un résultat final
            exceptionnel. Nous fournissons des produits de haute qualité et un
            service client dévoué pour garantir votre satisfaction. Confiez-nous
            votre projet résidentiel ou commercial et laissez-nous réaliser vos
            aspirations en matière de revêtements avec professionnalisme et
            excellence
          </p>
          <button className="btn-primary" onClick={() => navigate("/produits")}>
            Explorer plus
          </button>
        </div>
        <div className="products_slider">
          <ProductsSlider />
        </div>
        {/* <div className="second_sec_image">
          <div className="title_container">
            <img src={arrow} className="marbre_arrow" alt="arrow"></img>
            <h3>Marbre</h3>
            <p>Sogerev INTER</p>
          </div>
        </div> */}
      </div>
      {/* <div ref={projectsSliderRef}>
        <HomeTitle tit="Nos" le="Projets" />
        <ProjectsSlider projects={projectsData} />
      </div> */}
    </>
  );
};

export default Home;
