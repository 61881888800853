import React from "react";
import "./CSS/aboutCard.css";
const AboutCard = ({ icon, heading, text }) => {
  return (
    <div className="about_card">
      <img src={icon} />
      <h3> {heading} </h3>
      <p className="about_card_paragraph"> {text} </p>
    </div>
  );
};

export default AboutCard;
