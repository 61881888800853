import React from "react";

const HeaderBadge = ({ imgSrc, headingText, paragraphText }) => {
  return (
    <div className="badge_container">
      <div>
        <img src={imgSrc} alt="Badge Image" className="header_badge" />
      </div>
      <div>
        <h6>{headingText}</h6>
        <p>{paragraphText}</p>
      </div>
    </div>
  );
};

export default HeaderBadge;
